/*#region general*/
#main{
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  color: white;
}

.border{
  border-color: wheat;
  border-width: 1px;
  border-style: none;
  height: 100%;
  width: 100%;
}

.layout-layer{
  position: absolute;
  width: 100%;
  height: 100%;
}

p{
  font-family: "Lexend Exa", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  margin: 0%;
}

.game-ui{
  font-size: auto;
  padding-left: 8px;
  padding-right: 8px;;
  background-size: contain;
  background-position-y: center;
  background-repeat: no-repeat;
  background-color: RGBA(255,255,255,0);
}

.label-img{
  background-color: RGBA(255,255,255,1);
  border-radius: 10px;
}

.label-back{
  background-image: url("assets/label.svg");
}

.label-info-back{
  background-image: url("assets/statusreport-label.svg");
}


button{
  z-index: 10;
  background-image: url("assets/button_outline_2.svg");
  background-size: contain;
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: center;
  color: white;
  font-size: 3vw;
  background-color: RGBA(0, 0, 0, 0);
}



button:hover{
  filter: brightness(0.8)
}

/*#endregion*/


/*#region Intro Layout

*/
#intro-container{
  color: white;
  background-color: RGBA(0, 0, 0, 0.8);
  overflow: hidden;
  position: absolute;
}

#intro-container-top{
  height: 33%;
  display: flex;
}

#intro-game-logo-container{
  width: 25%;
  text-align: right;
}
#intro-game-logo-img{
  height: 100%;
}

#intro-game-text-container{
  width: 75%;
  font-size: min(4vw, 42px);
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 5%;
}

#intro-container-bot{
  display: flex;
  flex-direction:row;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 66%;
  width: 80%;
  margin: auto;
}

#intro-instructions-container{
  width: 50%;
  height: 100%;
  display: flex;
  overflow: hidden;
  transition-duration: 0.5s;
  margin: auto;

}

#intro-instructions-animation{
  margin: auto;
  transform: scale(1.3);
}

#intro-button-container{
  height: 100%;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  transition-duration: 0.5s;
}

#intro-button{
  width: 60%;
  height: 30%;
  text-align: center;
  margin: auto;
}

#intro-button-contents{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-5%, -5%);
}
#intro-button-icon-play-container{
  height: 100%;
  background-color: RGBA(0, 0, 0, 0);
}

#intro-button-icon-play{
  height: 50%;
  transform: translateY(50%);
  margin-right: 0%;
}





/*#endregion*/


/*#region Game HUD Layout

*/
#game-hud{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

#game-hud-left{
  width: 50%;
  text-align: left;
  display: flex;
  height: 15%;

  padding: 2%;
  padding-top: 0%;
  vertical-align: center;

}
#game-hud-right{
  width: 50%;
  text-align: right;
  flex-direction: row-reverse;
  display: flex;
  height: 15%;

  padding: 2%;
  padding-top: 0%;
  vertical-align: center;
}
.shift-left{
  margin-left: -3%;
  z-index: 2;
}

.game-hud-item-container{
  height: 100%;
  width: fit-content;
  display: flex;
  vertical-align: center;
  align-items: center;
}

.game-hud-label-contents{
  width: 80%;
  height: 70%;
  margin: 5%;
  display: flex;
  vertical-align: center;
  align-items: center;
}
.game-hud-label-contents.r-c{
  flex-direction: row-reverse;
  align-items: right;
}

button.cancel{
  background-image: url("assets/cancel_button.svg");
  height: 90px;
  width: auto;
  aspect-ratio: 1.43/1.24;
  margin: 0%;
  padding: 0%;
  z-index: 1;
}

.assignment-bubble{
    height: 90px;
    width: auto;
    aspect-ratio: 2.96/1.25;
    line-height: 90px;
    color: white  ;
    padding: 2%;
    overflow: visible;
    padding: 0%;
    margin: 0%;
}

.l{
  text-align: left;
}

.r{
  text-align: right;
}

#timer-icon-img{
  height: 50px;
}

.game-info{
  font-size: 38px;
  margin-left: 5%;
}
#goal-header{
    font-size: 12px;
}

/*#endregion*/


/*#region Pause Information*/
#pause-gui{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: RGBA(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
}

#pause-panel-container{
  position: absolute;
  margin: auto;
  height: 60%;
  /*background-color: wheat;*/
  aspect-ratio: 5/3;
  display: block;
}

#pause-panel-container-top{
  /*background-color: wheat;*/
  width: 100%;
  height: 60%;
  display: flex;
}

#pause-panel-container-bot{
  /*background-color: wheat;*/
  width: 100%;
  height: 40%;
  display: flex;
}

#pause-game-graphic-container{
  position: relative;
  height: 100%;
  width: 40%;
  /*background-color: blue;*/
}

#pause-game-graphic{
  width: 100%;
  height: 100%;
  transform: translate(-10%, -15%);
}

#pause-info-container{
  position: relative;
  height: 100%;
  width: 60%;
  /*background-color: cyan;*/
  display: flex;
  align-items: center;
  justify-content: center;
}



#pause-info-panel{
  width: 80%;
  height: 85%;
  font-size: 3vh;
  color: black;
  border: none;
  padding: 0%;
}

#pause-info-panel-text{
  border: none;
}

#pause-info-h{
  border: none;
  margin: 3%;
  margin-bottom: 4%;
  text-align: center;
  width: 75%;
  padding-top: 1%;
}

#pause-info-data{
  width: 100%;
  border: none;
}

#pause-info-reps-text{
  text-align: right;
  width: 20%;
  border: none;
}

.pause-info-row{
  width: 100%;
  min-height: 6.5vh;
  border: none;
  font-size: 1vw;
  margin-left: 5%;
  padding: 0%;
  display: flex;
  align-items: center;
}

.pause-info-row p{
  border: none;
  font-size: 3vh;
}

.pause-info-label{
  width: 55%;
  color: grey;
}

#pause-info-right{
  width: 25%;
  text-align: right;
  border: none;
  padding-right: 10%;
}

.status-report-label{
  border: none;
}



#pause-quit-container{
  position: relative;
  height: 100%;
  width: 33%;
  bottom: 0%;
  /*background-color: red;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

#pause-quit-button{
  height: 80%;
  margin-left: 50%;
}

#pause-continue-container{
  position: relative;
  height: 100%;
  width: 67%;
  bottom: 0%;
  /*background-color: orange;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

#pause-continue-contents{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 80%;
  transform: translate(-5%,-5%);
}

#pause-continue-button{
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vh;
}



/*#endregion*/

/*#region Game Over HUD Layout

*/

#gameover{
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    background-color: RGBA(0, 0, 0, 0.8);
    opacity: 0;
}

#gameover-bubble{
    position: absolute;
    top:50%;
    transform: translate(30%, -50%);
    width: 60%;
    font-size: 24px;
    background-color: RGBA(255, 255, 255, 1);
    padding: 2%;
}

#gameover-doc-img{
    left: 0%;
    height: min(140px, 20vh);
    transform: translateX(0%);
    position:relative;
    margin: auto;
}

#gameover-text{
    font-size: min(2vi, 24px);
    width: 100%;
    text-align: center;
}

#gameover-return{
  position:fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  margin: auto
}

/*#endregion

/* Animation classes */

/*#region Animation classes

*/

.hidden{
  visibility: hidden;
}

.fade-in {
  visibility: visible;
  opacity: 0;
  animation: fade-in 0.7s ease-in forwards;
}
  
div.fade-out {
  animation: fade-out 0.7s ease-out forwards;
}

.animate-left{
  transform: translateX(-100%);
}

.slide-in-L{
  animation: slide-in-L 1.0s ease-out forwards;
}

.slide-out-L{
  animation: slide-out-L 1.0s ease-in forwards;
}

.slide-in-R{
  animation: slide-in-R 1.0s ease-out forwards;
}

.slide-out-R{
  animation: slide-out-R 1.0s ease-in forwards;
}


/*#endregion*/
  
/*#region AnimationKeyframes

*/

  @keyframes slide-in-L{
    0% {
      transform: translateX(-100%); 
      opacity: 0;
      visibility: hidden;

    }
    100% {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;

    }
  }

  @keyframes slide-out-L{
    0% {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;

    }
    100% {
      transform: translateX(-100%); 
      opacity: 0;
      visibility: hidden;


    }


  }

  @keyframes slide-in-R{
    0% {
      transform: translateX(100%); 
      opacity: 0;
      visibility: hidden;


    }
    100% {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;

    }
  }

  @keyframes slide-out-R{
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(100%); 
      opacity: 0;
      visibility: hidden;


    }


  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
      visibility: hidden;

    }
  
    100% {
      opacity: 1;
      visibility: visible;

    }
  }
  
  @keyframes fade-out {
    0% {
      opacity: 1;
      visibility: visible;

    }
  
    100% {
      opacity: 0;
      visibility: hidden;

    }
  }

/*#endregion*/


#camera_canvas {
  transform: scaleX(-1);
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: transparent;
  image-rendering: pixelated;
}

.camera-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}